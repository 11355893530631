import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { ethers } from 'ethers';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useNetwork } from 'wagmi';
import {
  tokenAddress,
  surveyorProxyOFTAddress,
  surveyorOFTAddress,
  ARBITRUM_CHAIN_ID,
  LZ_ARBITRUM_ID,
  LZ_AVAX_ID,
} from './constants';
import surveyorToken from './abis/surveyorToken.json';
import surveyorProxyOFT from './abis/surveyorProxyOFT.json';
import surveyorOFT from './abis/surveyorOFT.json';
import './surveyor.css';

export default function SurveyorOFT() {
  const [userTokenBalance, setUserTokenBalance] = useState(0n);
  const [bridgeFee, setBridgeFee] = useState('0');
  const [bridgeFeeBN, setBridgeFeeBN] = useState(0n);
  const [amount, setAmount] = useState('');
  const { address: userAddress } = useAccount();
  const { chain } = useNetwork();

  const floatRegexp = useMemo(() => /^[+-]?\d*(?:[.,]\d*)?$/, []);

  const getUserBalance = useCallback(async () => {
    const provider = new ethers.BrowserProvider(window.ethereum);
    try {
      const OftTokenContract = new ethers.Contract(
        chain?.id === ARBITRUM_CHAIN_ID ? tokenAddress : surveyorOFTAddress,
        surveyorToken,
        provider,
      );
      const balance = await OftTokenContract.balanceOf(userAddress);
      console.log('balance: ', balance.toString());
      setUserTokenBalance(balance);
    } catch (err) {
      console.error('getUserBalance error: ', err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddress, chain?.id]);

  const getBrigeFee = async () => {
    const provider = new ethers.BrowserProvider(window.ethereum);
    if (!amount || !userAddress) return;
    try {
      // const signer = provider.getSigner();
      const OftTokenContract = new ethers.Contract(
        chain?.id === ARBITRUM_CHAIN_ID
          ? surveyorProxyOFTAddress
          : surveyorOFTAddress,
        surveyorProxyOFT,
        provider,
      );
      const abiCoder = new ethers.AbiCoder();

      const toAddress = abiCoder.encode(['address'], [userAddress]);
      const dstChainId =
        chain?.id === ARBITRUM_CHAIN_ID ? LZ_AVAX_ID : LZ_ARBITRUM_ID;
      const fee = await OftTokenContract.estimateSendFee(
        dstChainId,
        toAddress,
        ethers.parseUnits(amount, 18),
        false,
        '0x',
      );
      console.log('fee: ', ethers.formatEther(fee[0]));
      setBridgeFeeBN(fee[0]);
      setBridgeFee(parseFloat(ethers.formatEther(fee[0])).toFixed(6));
    } catch (err) {
      console.error('getBridgeFee error: ', err);
    }
  };

  const handleBridgeTokens = async () => {
    const provider = new ethers.BrowserProvider(window.ethereum);
    if (!amount || !userAddress) return;
    try {
      const signer = await provider.getSigner();
      const OftTokenContract = new ethers.Contract(
        chain?.id === ARBITRUM_CHAIN_ID
          ? surveyorProxyOFTAddress
          : surveyorOFTAddress,
        chain?.id === ARBITRUM_CHAIN_ID ? surveyorProxyOFT : surveyorOFT,
        signer,
      );
      const parsedAmount = ethers.parseUnits(amount, 18);

      //if on src chain(goerli), check allowance
      if (chain?.id === ARBITRUM_CHAIN_ID) {
        const tokenContract = new ethers.Contract(
          tokenAddress,
          surveyorToken,
          signer,
        );
        const allowance = await tokenContract.allowance(
          userAddress,
          surveyorProxyOFTAddress,
        );

        if (allowance < parsedAmount) {
          try {
            const tx = await tokenContract.approve(
              surveyorProxyOFTAddress,
              parsedAmount,
            );
            const receipt = await tx.wait();
            console.log('approval receipt: ', receipt);
            console.log('hash: ', receipt?.hash);
            alert('Approved successfully');
          } catch (err) {
            console.error('approval error: ', err);
            return;
          }
        }
      }

      let gasEstimate;

      try {
        const gas = await OftTokenContract[
          chain?.id === ARBITRUM_CHAIN_ID ? 'bridge' : 'bridgeOFT'
        ].estimateGas(parsedAmount, {
          value: bridgeFeeBN,
        });
        gasEstimate = gas;
        console.log('gas estimate :', gas);
      } catch (e) {
        console.error('gas estimate err', e);
        return;
      }

      const tx = await OftTokenContract[
        chain?.id === ARBITRUM_CHAIN_ID ? 'bridge' : 'bridgeOFT'
      ](parsedAmount, {
        gasLimit: gasEstimate,
        value: bridgeFeeBN,
      });

      const receipt = await tx.wait();
      console.log('receipt: ', receipt);
      console.log('hash: ', receipt?.hash);
      await getUserBalance();
      alert('Bridged successfully');
    } catch (err) {
      console.error('handleBridgeTokens error: ', err);
    }
  };

  useEffect(() => {
    getBrigeFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, chain?.id]);

  useEffect(() => {
    getUserBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain?.id]);

  const handleOnAmountChange = useCallback(
    (e) => {
      const value = e?.target?.value?.toString();
      const valid = floatRegexp.test(value?.replace(/,/g, ''));
      console.log('valid: ', valid);
      console.log('value: ', value);
      if (!valid) e?.preventDefault();
      if (valid) {
        setAmount(value.replace(/,/g, '') || '');
      }
      if (value === '') {
        setAmount('');
      }
    },
    [floatRegexp],
  );

  return (
    <div>
      <h1>Bridge Tokens</h1>
      <div className="account">
        <h3 style={{ marginRight: '1em' }}>
          Current chain balance:{' '}
          {parseFloat(
            ethers.formatUnits(userTokenBalance, 18),
          ).toLocaleString()}{' '}
          SVT
        </h3>
        <h3 style={{ marginRight: '1em' }}>
          Bridge fee: {bridgeFee} {chain?.nativeCurrency?.symbol}
        </h3>
        <ConnectButton />
      </div>
      <div>
        <h3>Amount to Bridge:</h3>
        <input
          type="text"
          name="tokenSend"
          placeholder="0.00"
          value={amount}
          onChange={handleOnAmountChange}
        ></input>
      </div>
      <p>
        <button
          onClick={() => {
            handleBridgeTokens();
          }}
        >
          Bridge Tokens
        </button>
      </p>
    </div>
  );
}
